import Utils from '@/lin/util/util'
import adminConfig from './admin'
// import pluginsConfig from './plugin'
// eslint-disable-next-line import/no-mutable-exports
let homeRouter = [
  // {
  //   title: '泾川县公证处后台管理系统',
  //   type: 'view',
  //   name: Symbol('about'),
  //   route: '/about',
  //   filePath: 'view/about/about.vue',
  //   inNav: true,
  //   icon: 'iconfont icon-iconset0103',
  //   isElementIcon: false,
  //   order: 1,
  // },
  {
    title: '申办预约',
    type: 'view',
    name: Symbol('order'),
    route: '/order/list',
    filePath: 'view/mini_order/order.vue',
    inNav: true,
    icon: 'iconfont icon-clock',
    isElementIcon: false,
    order: 1,
  },
  {
    title: '最新公告',
    type: 'view',
    name: Symbol('notice'),
    route: '/notice',
    filePath: 'view/notice/notice-list.vue',
    inNav: true,
    icon: 'iconfont icon-rizhiguanli',
    isElementIcon: false,
    order: 2,
  },
  {
    title: '公证人员',
    type: 'view',
    name: Symbol('person'),
    route: '/person/list',
    filePath: 'view/notary_person/person-list.vue',
    inNav: true,
    icon: 'iconfont icon-huiyuan1',
    isElementIcon: false,
    order: 2 ,
  },
  {
    title: '公证风采',
    type: 'view',
    name: Symbol('mien'),
    route: '/mien',
    filePath: 'view/mien/mien-list.vue',
    inNav: true,
    icon: 'iconfont icon-art-gallery-',
    isElementIcon: false,
    order: 3,
  },

  {
    title: '公证案例',
    type: 'view',
    name: Symbol('case'),
    route: '/case/list',
    filePath: 'view/notary_case/case-list.vue',
    inNav: true,
    icon: 'iconfont icon-yunyingguanli_fuwufenzuguanli',
    isElementIcon: false,
    order: 4,
  },
  {
    title: '资料下载',
    type: 'view',
    name: Symbol('download'),
    route: '/dowanload',
    filePath: 'view/download/download-list.vue',
    inNav: true,
    icon: 'iconfont icon-naozhongxiaoxitixing',
    isElementIcon: false,
    order: 5,
  },
  {
    title: '公证知识',
    type: 'view',
    name: Symbol('knowledge'),
    route: '/knowledge',
    filePath: 'view/knowledge/knowledge-list.vue',
    inNav: true,
    icon: 'iconfont icon-kecheng',
    isElementIcon: false,
    order: 6,
  },

  {
    title: '办理流程',
    type: 'view',
    name: Symbol('flow'),
    route: '/flow',
    filePath: 'view/flow/flow-list.vue',
    inNav: true,
    icon: 'iconfont icon-icon-test',
    isElementIcon: false,
    order: 9,
  },
  {
    title: '法律法规',
    type: 'view',
    name: Symbol('law'),
    route: '/law',
    filePath: 'view/law/law-list.vue',
    inNav: true,
    icon: 'iconfont icon-weibiaoti--',
    isElementIcon: false,
    order: 8,
  },

  {
    title: '关于我们',
    type: 'view',
    name: Symbol('about_us'),
    route: '/about_us',
    filePath: 'view/about_us/about_us-list.vue',
    inNav: true,
    icon: 'iconfont icon-pifu',
    isElementIcon: false,
    order: 10,
  },


  {
    title: '个人中心',
    type: 'view',
    name: Symbol('center'),
    route: '/center',
    filePath: 'view/center/center.vue',
    inNav: false,
    icon: 'iconfont icon-rizhiguanli',
    isElementIcon: false,
  },
  {
    title: '404',
    type: 'view',
    name: Symbol('404'),
    route: '/404',
    filePath: 'view/error-page/404.vue',
    inNav: false,
    icon: 'iconfont icon-rizhiguanli',
    isElementIcon: false,
  },
  adminConfig,
]

// 接入插件
// const plugins = [...pluginsConfig]
// filterPlugin(homeRouter)
// homeRouter = homeRouter.concat(plugins)

// 处理顺序
homeRouter = Utils.sortByOrder(homeRouter)
deepReduceName(homeRouter)

export default homeRouter

/**
 * 筛除已经被添加的插件
 */
function filterPlugin(data) {
  if (plugins.length === 0) {
    return
  }
  if (Array.isArray(data)) {
    data.forEach(item => {
      filterPlugin(item)
    })
  } else {
    const findResult = plugins.findIndex(item => data === item)
    if (findResult >= 0) {
      plugins.splice(findResult, 1)
    }
    if (data.children) {
      filterPlugin(data.children)
    }
  }
}

/**
 * 使用 Symbol 处理 name 字段, 保证唯一性
 */
function deepReduceName(target) {
  if (Array.isArray(target)) {
    target.forEach(item => {
      if (typeof item !== 'object') {
        return
      }
      deepReduceName(item)
    })
    return
  }
  if (typeof target === 'object') {
    if (typeof target.name !== 'symbol') {
      target.name = target.name || Utils.getRandomStr()
      target.name = Symbol(target.name)
    }

    if (Array.isArray(target.children)) {
      target.children.forEach(item => {
        if (typeof item !== 'object') {
          return
        }
        deepReduceName(item)
      })
    }
  }
}
